import React from 'react';
import JWPlayer from '@jwplayer/jwplayer-react';
import Seo from "../components/seo";
import Layout from "../components/layout";
import {DataProvider} from "../contentful/data-provider";
import {graphql} from "gatsby";

const VastTags = ({data}) => {
    const dataProvider = new DataProvider(data);

    const vastTag = data.contentfulLandingPage?.vastTag?.vastTag

    return (
        <Layout dataProvider={dataProvider}>
            <Seo dataProvider={dataProvider} title={`GlassView | Work - Sample Vast tag`} />
            <main className={"pt-24 lg:pt-24 pb-20"}>
                <section className='pb-10 md:pb-20'>
                    <div className={"container text-center flex justify-center"}>
                        <JWPlayer
                            playlist={{
                                file: `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`
                            }}
                            library="https://cdn.jwplayer.com/libraries/cDnha7c4.js"
                            advertising={{
                                vpaidcontrols: true,
                                client: "vast",
                                tag: vastTag
                            }}
                        />
                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default VastTags;

export const query = graphql`
    query LandingPageVASYBySlug($slug: String!, $locale: String) {
        contentfulLandingPage(slug: { eq: $slug }) {
            id
            slug
            title
            vastTag {
                vastTag
            }
        }
        allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
            edges {
                node {
                    ...Menus
                }
            }
        }
    }
`;
